import { useState, useEffect, useMemo, forwardRef, Fragment } from "react";
import {
  Link,
  LinkProps,
  useLocation,
  Outlet,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  CssBaseline,
  Stack,
  useMediaQuery,
  Grid,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { MENUITEMS } from "../utils/Const";
import { MenuItem } from "../types/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import Logo from "../resources/images/mapprisk_logo.svg";
import theme from "../theme/theme";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";
import MessageManager from "../components/utils/MessageManager";
import { CONSTANT } from "../utils/Constants";
import Banner from "../resources/images/banner-dashboard.jpg";
import ResourceAccess from "../components/security/ResourceAccess";
import { UrlBase } from "../url/Urls";
import { getLastBalance } from "../services/balance/BalanceService";
import moment from "moment";
import Loading from "../components/utils/Loading";
import CompaniesAndSubCompanySelect from "../components/CompaniesAndSubCompanySelect";

const drawerWidth = 264;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const IconHamburger = styled("i")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "1px",
  content: '""',
  cursor: "pointer",
  display: "block",
  height: "3px",
  transition: "background .1s ease-in-out,color .1s ease-in-out",
  width: "24px",
  position: "relative",
  "&:before,&:after": {
    background: theme.palette.primary.main,
    borderRadius: "1px",
    content: '""',
    cursor: "pointer",
    display: "block",
    height: "3px",
    transition: "background .1s ease-in-out,color .1s ease-in-out",
    position: "absolute",
  },
  "&:before": {
    top: "-7.5px",
    width: "24px",
  },
  "&:after": {
    bottom: "-7.5px",
    width: "16px",
  },
}));

const IconNestedLink = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  "&:before": {
    content: '"→"',
    display: "inline-block",
    left: "0",
    position: "relative",
    transform: "translateX(0px)",
    transition: "all 0.1s ease 0s",
  },
}));

const ListItemTextNested = styled(ListItemText)(({ theme }) => ({
  "& span": {
    fontSize: "90%",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  padding: "0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Footer = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
}));

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} color="mappRisk.main" />
      )),
    [to]
  );

  return <ListItem {...props} component={to ? renderLink : "button"} />;
}

const filterByPermissions = (
  items: MenuItem[],
  permission: string
): MenuItem[] => {
  const elements = items.filter((item) =>
    item.permissions.includes(permission)
  );
  elements.forEach((el, index) => {
    if (el.expand) {
      elements[index] = {
        ...el,
        children: filterByPermissions(el.children, permission),
      };
    }
  });
  return elements;
};

const DashboardHome = () => {
  const navigate = useNavigate();
  const {
    authInfo,
    authenticate,
    errorMsg,
    successMsg,
    logoutProvider,
    resetErrorMsg,
    resetSuccessMsg,
    isLoading,
    setErrorMsg,
  } = useApp();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [errorNoMoreViewsMsg, setErrorNoMoreViewsMsg] = useState("");
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const user = {
    is_superuser: true,
    is_client: false,
    is_company: false,
  };
  const menuItems = getMenuItems();
  const [expanded, setExpanded] = useState<boolean[]>(
    new Array(menuItems.length).fill(false)
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const selectedMenuItemIndex = menuItems.findIndex(
      (item) =>
        !!item.expand &&
        !!item.children.find((child) => child.to === location.pathname)
    );
    if (selectedMenuItemIndex > -1) {
      setExpanded(
        expanded.map((el, i) => {
          if (i === selectedMenuItemIndex) {
            return !el;
          }
          return el;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getMenuItems() {
    let menuItems = [...MENUITEMS];
    if (user.is_superuser) {
      menuItems = filterByPermissions(menuItems, "is_superuser");
    }
    if (user.is_company) {
      menuItems = filterByPermissions(menuItems, "is_company");
    }
    if (user.is_client) {
      menuItems = filterByPermissions(menuItems, "is_client");
    }
    if (!user.is_superuser && !user.is_company && !user.is_client) {
      menuItems = filterByPermissions(menuItems, "is_user");
    }
    return menuItems;
  }

  const handleClick = (index: number, item: MenuItem) => {
    if (item.expand) {
      setExpanded(
        expanded.map((o, i) => {
          if (i === index) {
            return !o;
          }
          return o;
        })
      );
    }
  };

  const handleSelected = (item: MenuItem) => {
    return item.to === location.pathname;
  };

  const handleFetchData = async () => {
    // setLoading && setLoading(true);
    try {
      /* let data = await getLastBalance();
      setDate(data.Date); */
    } catch (error: any) {
      // setLoading && setLoading(false);
      // setErrorMsg && setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(companyIndexDB)

  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: "center", py: 2 }}>
        <img src={Logo} alt="MappRisk Compliance" width="80" />
      </Toolbar>
      <Divider sx={{ mb: theme.spacing(3.25) }} />

      <Typography variant="h5" align="center" color="mappRisk.main">
        {authInfo?.userRole?.roleName === "Administrador"
          ? "Portal Administrador Compliance"
          : "Portal Compliance"}
      </Typography>

      <Divider sx={{ mt: theme.spacing(3.25) }} />
      <List sx={{ pt: 0 }}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            {item.label ===
            properties.com_mapprisk_label_dashboard_menu_logout ? (
              <ListItemLink
                button
                onClick={logoutProvider}
                selected={handleSelected(item)}
                to={item.to}
                sx={{
                  borderBottom: `1px solid ${theme.palette.text.secondary}`,
                  padding: ".625rem 1.125rem .625rem 1.725rem",
                }}
              >
                <ListItemIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: ".8125rem",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemLink>
            ) : (
              <>
                <ResourceAccess
                  isCode={true}
                  pathOrCode={`${item.permissions[1]}`}
                >
                  <ListItemLink
                    button
                    onClick={() => handleClick(index, item)}
                    selected={handleSelected(item)}
                    to={item.to}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.text.secondary}`,
                      padding: ".625rem 1.125rem .625rem 1.725rem",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: ".8125rem",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {item.expand ? (
                      expanded[index] ? (
                        <ExpandLess sx={{ color: "mappRisk.main" }} />
                      ) : (
                        <ExpandMore sx={{ color: "mappRisk.main" }} />
                      )
                    ) : null}
                  </ListItemLink>
                </ResourceAccess>
                {item.expand && (
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      {item.children.map((child) => (
                        <Fragment key={child.label}>
                          <ResourceAccess
                            pathOrCode={`/${child.to}`}
                            isCode={false}
                          >
                            <ListItemLink
                              button
                              //key={child.label}
                              to={child.to}
                              selected={child.to === location.pathname}
                              sx={{
                                borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                padding: ".625rem 1.5rem .625rem 2.35rem",
                              }}
                            >
                              <IconNestedLink />
                              <ListItemTextNested primary={child.label} />
                            </ListItemLink>
                          </ResourceAccess>
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </Fragment>
        ))}
      </List>
    </div>
  );

  if (!authenticate) {
    //console.log(authenticate);
    //console.log("navigate es");
    navigate("/", { replace: true });
    //window.location.replace("/");
  }

  const closeNewTab = async () => {
    window.opener = null;
    window.close();
    window.location.replace("https://www.google.com");
    setErrorNoMoreViewsMsg("");
  };

  useEffect(() => {
    setErrorNoMoreViewsMsg("");
    function messageListener(e: any) {
      const { data } = e;

      if (data === "ping") {
        setErrorMsg &&
          setErrorMsg("No puedes abrir más de una página al mismo tiempo");
        bc.postMessage("pong");
      }

      if (data === "pong") {
        setErrorNoMoreViewsMsg(
          "No puedes abrir más de una página al mismo tiempo, esta ventana se cerrara"
        );
        setTimeout(() => {
          window.close();
        }, 1000);
        // Do something here to block the page and/or message the user
      }
    }

    const bc = new BroadcastChannel("single_tab");

    bc.postMessage("ping");

    bc.addEventListener("message", messageListener);

    return () => bc.removeEventListener("message", messageListener);
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {errorNoMoreViewsMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorNoMoreViewsMsg}
          details=""
          callback={closeNewTab}
        />
      )}
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                px: 6,
                minHeight: "54px",
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <IconHamburger />
            </IconButton>
            <Typography
              color="inherit"
              sx={{ fontSize: "1rem", flexGrow: 1 }}
              noWrap
              component="div"
            >
              {authInfo?.companyInfo[0]?.name
                ? authInfo?.subcompanyInfo[0]?.name
                  ? `${authInfo.companyInfo[0].name} / ${authInfo.subcompanyInfo[0].name}`
                  : authInfo.companyInfo[0].name
                : ""}
            </Typography>

            <Stack direction="row" spacing={4} alignItems={"center"}>
              {!isMobile && authInfo !== null
                ? authInfo.userRole.id === 1 && <CompaniesAndSubCompanySelect />
                : ""}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                //onClick={handleMenu}
                color="inherit"
                sx={{
                  "&:hover, &:focus": {
                    background: "transparent",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <PersonIcon sx={{ fontSize: "24px" }} color="primary" />
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  {authInfo?.name}
                </Typography>
                {/* <ExpandMore /> */}
              </IconButton>
              {/* <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItemMui
                    onClick={logoutProvider}
                    sx={{ borderRadius: "0!important" }}
                  >
                    Cerrar sesión
                  </MenuItemMui>
                </Menu> */}
            </Stack>
          </Toolbar>
          <Divider sx={{ mb: theme.spacing(3.25) }} />
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open={!mobileOpen}
          >
            {drawer}
          </Drawer>
        </Box>
        <Main open={!mobileOpen}>
          <Toolbar
            sx={{
              [theme.breakpoints.up("sm")]: {
                minHeight: "54px",
              },
            }}
          />
          <Box sx={{ flex: "1 1" }}>
            {isMobile && authInfo !== null
              ? authInfo.userRole.id === 1 && <CompaniesAndSubCompanySelect />
              : ""}
            <Outlet />
          </Box>
          <Footer>
            <Typography variant="body2">
              Copyright 2023. Todos los derechos reservados.
            </Typography>
          </Footer>
        </Main>
      </Box>
    </>
  );
};

export default DashboardHome;
