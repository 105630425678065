import { CONSTANT } from "./Constants";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

  /** maintenance urls */
  REQUEST_CERTCATEGORY_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${page}/${size}`,
  REQUEST_CERTCATEGORY: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${id ? id : ""}`,

  REQUEST_CERTIFICATE_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
  REQUEST_CERTIFICATE: (id: any) =>
    CONSTANT.DISPATCHER_PERFIL_DOCUMENTS_PREFIX +
    `/certificate/pdf/certificate/${id ? id : ""}`,

  // Assets

  REQUEST_RUBRO_LIST: (page: number, size: number, filter?: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/DataTable/list/${
      filter !== ""
        ? "{description}?tableName=" + filter
        : "?page=" + page + "&size=" + size
    }&sort=desc`,
  REQUEST_RUBRO_WITH_URL: (url: string, size: number, page: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/${url}/list/?page=${page}&size=${size}&type=j`,
  REQUEST_RUBRO_WITH_URL_ID: (url: string, id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/${url}/filter/?filter=${id}`,
  REQUEST_CREATE_RUBRO: (url: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/${url}/create`,
  REQUEST_UPDATE_RUBRO: (url: string, id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/${url}/${id}`,
  REQUEST_DELETE_RUBRO: (url: string, id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/${url}/${id}`,

  // Assets

  // Maintenance Area

  REQUEST_AREA_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/area/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_AREA_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area`,
  REQUEST_AREA_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/${id}`,

  // Maintenance Section

  REQUEST_SECTION_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/section/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_SECTION_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/section`,
  REQUEST_SECTION_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/section/${id}`,

  // Profile Matriz

  REQUEST_PROFILE_MATRIZ_USER: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/profile/filter/?page=${page}&size=${size}&sort=id,desc`,
  /* REQUEST_PROFILE_MATRIZ_USER: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/profile/filter/?page=${page}&size=${size}&sort=id,desc`, */
  REQUEST_PROFILE_MATRIZ_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/profile`,
  /* REQUEST_PROFILE_MATRIZ_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/profile/create`, */
  REQUEST_PROFILE_MATRIZ_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/profile/${id}`,
  REQUEST_PROFILE_MATRIZ_BLACKLISTMATCH: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/profile/${id}`,
  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/v1/maintenance/profile/122  blackListMatch

  // Field Section

  REQUEST_FIELD_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/field/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_FIELD_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/field`,
  REQUEST_FIELD_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/field/${id}`,

  // Maintenance Accounts

  REQUEST_ACCOUNT_UPLOAD_FILE: (userCreated: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/account/upload?user_created=${userCreated}`,
  REQUEST_ACCOUNT_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/account/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_ACCOUNT_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account`,
  REQUEST_ACCOUNT_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account/${id}`,

  // Maintenance Category

  REQUEST_CATEGORY_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/category/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_CATEGORY_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category`,
  REQUEST_CATEGORY_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${id}`,

  // Maintenance Office

  REQUEST_OFFICE_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/office/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_OFFICE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office`,
  REQUEST_OFFICE_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office/${id}`,

  // Maintenance Report

  REQUEST_REPORT_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/report/filter/?page=${page}&size=${size}&sort=id,asc`,
  REQUEST_REPORT_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/report`,
  REQUEST_REPORT_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/report/${id}`,

  //  Report Generate Report

  REQUEST_REPORT_GENERATE:
    CONSTANT.DISPATCHER_REPORT_PREFIX + `/generatereport/download/`,

  // Maintenance Provider

  REQUEST_PROVIDER_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/provider/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_PROVIDER_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider`,
  REQUEST_PROVIDER_UPDATE_DELETE: (id: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider/${id}`,

  //Maintenance RiskLevels

  REQUEST_RISK_LEVELS_LIST: (companyId: string, subcompanyId: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/riskLevels?company-id=${companyId}${
      Number(subcompanyId) !== 0 ? "&sub-company-id=" + subcompanyId : ""
    }`,
  /*  REQUEST_RISK_LEVELS_LIST: (companyId: string, subcompanyId: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/riskLevels?company-id=${companyId}&sub-company-id=${subcompanyId}`, */
  CREATE_RISK_LEVELS_LIST: () =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/riskLevels/create`,
  UPDATE_RISK_LEVELS_LIST: (id1: number, id2: number) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/riskLevels/${id1}/${id2}`,

  /** users urls */

  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/v1/users/user?company=1&page=0&size=10

  REQUEST_USER_LIST: (page: string, size: string, company: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user?company=${company}&page=${page}&size=${size}&sort=id,desc`,
  REQUEST_USER_LIST_FILTER: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,

  REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,

  REQUEST_USER: (id: any) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  REQUEST_USER_CHANGE_PASSWORD:
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,

  /** Role urls */
  REQUEST_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
  REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,

  /** EmailRole urls */
  REQUEST_EMAIL_ROLE: (
    roleID?: string | null,
    filter?: string | null,
    currentPage?: number,
    size?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/email/${roleID ? roleID + "/" : ""}?${
      filter ? "filter=" + filter + "&" : ""
    }page=${currentPage}&size=${size}`,
  REQUEST_EMAIL_ROLE_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ""}`,
  DELETE_REQUEST_EMAIL_ROLE_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ""}`,
  REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

  /** DocumentRole urls */
  REQUEST_DOCUMENT_ROLE_LIST: (
    filter: string | null,
    page: number,
    size: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documentsRole/list/?${
      filter ? "filter=" + filter + "&" : ""
    }page=${page}&size=${size}`,
  REQUEST_DOCUMENT_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ""}`,

  // Balance urls

  REQUEST_BALANCE_UPLOAD_FILE: (userCreated: string, remove: boolean) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/balance/upload?user_created=${userCreated}&actual_remove=${remove}`,
  REQUEST_BALANCE_LIST: (page: number, size?: number, filter?: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/balance/filter/?page=${page}&size=${size}&sort=id,desc`,
  REQUEST_LAST_BALANCE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/balance/findDateUltReport`,
  /** document */
  REQUEST_DOCUMENT_LIST: (page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/list/?page=${page}&size=${size}`,
  REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
  REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/?${
      filter ? "filter=" + filter + "&" : ""
    }page=${page}&size=${size}&sort=id,desc`,
  REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (
    roleId: number,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/role/${roleId}?page=${page}&size=${size}?filter=${filter}`,

  /** Company urls */
  REQUEST_COMPANY: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/filter/?page=${page}&size=${size}`,
  REQUEST_COMPANY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/create`,
  REQUEST_COMPANY_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/${id ? id : ""}`,
  REQUEST_COMPANY_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/${id ? id : ""}`,
  REQUEST_COMPANY_BYID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/filter/?page=0&size=10&sort=desc&filter=${id ? id : ""}`,
  REQUEST_COMPANYID: (companyId: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/filter/?page=0&size=1&filter=companyId=${companyId}`,
  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/company/filter/?page=0&size=1&filter=companyId%3D21

  //Documents by Company
  DOCUMENTS_COMPANY_UPLOAD:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/upload`,
  DOCUMENTS_COMPANY_GET: (companyId: string, subCompany?: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/?companyId=${companyId}${
      subCompany ? "&subcompanyId=" + subCompany : ""
    }`,
  DOCUMENT_DELETE: (id: string, user: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/delete/` +
    id +
    `?user=` +
    user,

  /** SubCompany urls */
  REQUEST_SUBCOMPANY: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/Subcompany/filter/?page=${page}&size=${size}`,
  REQUEST_SUBCOMPANY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/create`,
  REQUEST_SUBCOMPANY_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/${id ? id : ""}`,
  REQUEST_SUBCOMPANY_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/${id ? id : ""}`,
  REQUEST_SUBCOMPANY_BYID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/Subcompany/filter/?page=0&size=10&sort=desc&filter=${id ? id : ""}`,
  REQUEST_SUBCOMPANY_BYID2: (page: string, size: string, companyId: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/Subcompany/filter/?page=${page}&size=${size}&companyId=${companyId}`,

  /** Company realations urls */
  REQUEST_COMPANYREL: (page: string, size: string, userid: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/CompanyRelation/filter/?page=${page}&size=${size}&userId=${userid}`,
  REQUEST_COMPANYREL_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/CompanyRelation/create`,
  REQUEST_COMPANYREL_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/CompanyRelation/${id ? id : ""}`,
  REQUEST_COMPANYREL_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/CompanyRelation/${id ? id : ""}`,

  /**Company Screening Relation */
  LIST_COMPANY_SCREENING:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/companies`,
  REQUEST_COMPANY_SCREENING:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/screening/link`,
  REQUEST_COMPANY_SCREENING_REL: (company: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/screening/relations?company=${company}`,

  // "https://mapprisk-client-screening-dispatcher.bitcode-enterprise.dev/v1/maintenance/company/companies"
  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/company/screening/link
  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/company/screening/relations?company=1

  /** ControlTraining urls */
  REQUEST_CONTROLTRAINING: (
    page: string,
    size: string,
    companyId: string,
    subcompanyId: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitation/filter/?page=${page}&size=${size}&company=${companyId}${
      subcompanyId ? "&subcompany=" + subcompanyId : ""
    }`,
  REQUEST_CONTROLTRAINING_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/capacitation/create`,
  REQUEST_CONTROLTRAINING_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/capacitation/${id ? id : ""}`,
  REQUEST_CONTROLTRAINING_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/capacitation/${id ? id : ""}`,
  REQUEST_CONTROLTRAINING_BYID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitation/filter/?page=0&size=10&id=${id ? id : ""}`,

  /** EMPLOYEES urls */
  REQUEST_EMPLOYEES: (
    page: string,
    size: string,
    companyId: string,
    subcompanyId: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/employee/filter/?page=${page}&size=${size}&company=${companyId}&subcompany=${subcompanyId}`,
  REQUEST_EMPLOYEES_CREATE:
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/createEmployee`,
  REQUEST_EMPLOYEES_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/employee/${id ? id : ""}`,
  REQUEST_EMPLOYEES_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/employee/${id ? id : ""}`,
  REQUEST_EMPLOYEES_BYID: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/employee/filter/?page=0&size=10&id=${id ? id : ""}`,

  // Matrix Group urls

  REQUEST_MATRIX_GROUPS: (
    page: string,
    size: string,
    companyId: string,
    subcompanyId: string,
    name?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/matrix-groups?company-id=${companyId}${
      subcompanyId ? `&sub-company-id=${subcompanyId}` : ""
    }${name ? `&name=${name}` : ""}&page=${page}&size=${size}`,
  REQUEST_MATRIX_GROUPS_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/matrix-groups`,
  REQUEST_MATRIX_GROUPS_DELETE: (id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/matrix-groups/${id}`,

  // Branch urls
  REQUEST_BRANCHES: (
    page: string,
    size: string,
    companyId: string,
    subcompanyId: string,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/branches/filter/?page=${page}&size=${size}&company=${companyId}${
      subcompanyId ? "&subcompany=" + subcompanyId : ""
    }${filter ? "&filter=" + filter : ""}`,
  REQUEST_BRANCHES_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/branches/create`,
  REQUEST_BRANCHES_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/branches/${id ? id : ""}`,
  REQUEST_BRANCHES_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/branches/${id ? id : ""}`,

  // deparment urls
  REQUEST_DEPARMENT: (
    page: string,
    size: string,
    companyId: string,
    subcompanyId: string,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/departments/filter/?page=${page}&size=${size}&company=${companyId}${
      subcompanyId ? "&subcompany=" + subcompanyId : ""
    }${filter ? "&filter=" + filter : ""}`,
  REQUEST_DEPARMENT_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/departments/create`,
  REQUEST_DEPARMENT_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/departments/${id ? id : ""}`,
  REQUEST_DEPARMENT_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/departments/${id ? id : ""}`,

  /** CapacitationEmployee urls */
  REQUEST_CAPACITATIONEMPLOYEE: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/filter/?page=${page}&size=${size}`,
  /* REQUEST_CAPACITATIONEMPLOYEE2: (page: string, size: string, employeeId: string, departmentId: string ,period: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/filter/?page=${page}&size=${size}&employeeId=${employeeId}&departmentId=${departmentId}&period=${period}`,   */
  REQUEST_CAPACITATIONEMPLOYEE2: (page: string, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/filter/?page=${page}&size=${size}`,
  REQUEST_CAPACITATIONEMPLOYEE_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/capacitationEmployee/create`,
  REQUEST_CAPACITATIONEMPLOYEE_UPDATE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/${id ? id : ""}`,
  REQUEST_CONTROLTRAININGEMPLOYEE_DELETE: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/${id ? id : ""}`,
  REQUEST_CAPACITATIONEMPLOYEECHART: (capacitationEmployeeId: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/capacitationEmployee/${capacitationEmployeeId}/calculate-hours-summary
    `,

  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/v1/documents/certificate/pdf/certificate/15

  /** authorityByRole */
  REQUEST_AUTHORITY_BY_ROLE_LIST: (
    roleId: string,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority-role/role/${roleId}?page=${page}&size=${size}${
      filter && "&filter=" + filter
    }`,
  /* REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && 'filter='+filter}`, */

  REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

  REQUEST_CLIENT_LIST: (
    endDate: any,
    page: number,
    size: string,
    startDate: any
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/client/{page}/{size}/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
  REQUEST_CLIENT: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ""}`,
  /* REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}/${page}/${size}`,   */
  REQUEST_STORES_CLIENT: (id: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/client/stores/${id ? id : ""}?page=${page}&size=${size}`,

  /**Dashboard */
  REQUEST_CATEGORY_COUNT:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/category-count`,
  REQUEST_USER_WITH_ASSETS:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/users-with-assets`,
  REQUEST_ASSETS_BY_CATEGORY:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-category`,
  REQUEST_PROVIDER_COUNT:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/provider-count`,
  REQUEST_ASSETS_BY_MONTH:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-month`,

  REQUEST_CUSTOMER_STAGE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-stage`,
  REQUEST_CUSTOMER_PERSON_TYPE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-person-type`,
  REQUEST_CUSTOMER_REGISTERED:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer`,
  REQUEST_CUSTOMER_COMPARATION:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/clients-comparation`,
  REQUEST_CUSTOMER_YEAR:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/registered-customer-year`,
  REQUEST_CUSTOMER_LEADS:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/leads`,

  /**Factors by company */
  REQUEST_FACTORS_BY_COMPANY: (
    page: number,
    size: number,
    type: string,
    companyID: number,
    subCompanyID?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/factors?company-id=${companyID}${
      subCompanyID ? "&sub-company-id=" + subCompanyID : ""
    }&type=${type}&page=${page}&size=${size}`,
  REQUEST_FACTORS_CUD: (id?: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/factors${id ? "/" + id : ""}`,

  // SubElements to Options
  REQUEST_SUBELEMENTS_TO_OPTIONS: (
    page: number,
    size: number,
    factorOptionId: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/subElements?factor-option-id=${factorOptionId}&page=${page}&size=${size}`,
  CREATE_SUBELEMENT: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/subElements`,
  DELETE_SUBELEMENT: (id: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/subElements/${id}`,

  /**Single factors */
  REQUEST_SINGLE_FACTOR: (
    factor: string,
    page: number,
    size: number,
    filter?: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/factors/options?factor-id=${factor}${
      filter ? "&name=" + filter : ""
    }&page=${page}&size=${size}`,
  REQUEST_CUD_SINGLE_FACTOR: (id?: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/factors/options${id ? "/" + id : ""}`,
  // Contitution TIme

  REQUEST_NUMBER_INQUIRIES: (companyId?: string, subcompanyId?: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/remaining-request${companyId ? "?companyId=" + companyId : ""}${
      subcompanyId ? "?subcompanyId=" + subcompanyId : ""
    }`,
  REQUEST_MORE_INQUIRIES: (adminID: string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/notification/more-request?adminId=${adminID}`,

  REQUEST_EXTERNALSERVICE_INTERNALLIST: (
    company: string,
    subCompany?: string
  ) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX +
    `/get-file-information?company=${company}${
      subCompany ? "&subCompany=" + subCompany : ""
    }`,
  REQUEST_EXTERNALSERVICE_DELETE_INTERNALLIST: (id: string) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/delete-file/${id}`,

  REQUEST_DOCUMENT_UPD_INTERNALLIST: (
    userId: string,
    companyId: string,
    subCompanyId: string | null
  ) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX +
    `/uploadFile?userId=${userId}&companyId=${companyId}${
      subCompanyId !== "" ? "&subCompanyId=" + subCompanyId : ""
    }`,

  REQUEST_EXTERNALSERVICE_VALIDATEUSER:
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX +
    `/external-service/validate-user`,

  REQUEST_PDF_DOWNLOAD_INDIVIDUAL: (
    user: string,
    searchName: string,
    companyId: string,
    subCompanyId?: string
  ) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX +
    `/lexis-nexis/individual-pdf/?user=${user}&searchName=${searchName}&companyId=${companyId}${
      subCompanyId ? "&subCompanyId=" + subCompanyId : ""
    }`,
  REQUEST_PDF_DOWNLOAD_GENERAL: (
    user: string,
    searchName: string,
    companyId: string,
    actualPage: number,
    totalPages: number,
    subCompanyId?: string
  ) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX +
    `/lexis-nexis/general-pdf/?user=${user}&searchName=${searchName}&companyId=${companyId}${
      subCompanyId ? "&subCompanyId=" + subCompanyId : ""
    }&actualPage=${actualPage}&totalPages=${totalPages}`,

  REQUEST_PDFMATRIZ: (id: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/pdf/matriz/${id ? id : ""}`,

  REQUEST_BATCH_BY_COMPANY: (page: number, size: string) =>
    `/external-service/lexis-nexis/batch?page=${page}&size=${size}`,
  REQUEST_BATCH_DETAILS: (id: string, page: number, size: string) =>
    `/external-service/lexis-nexis/batch/detail?lotId=${id}&page=${page}&size=${size}`,
  REQUEST_BATCH_RESULT: (id: string, page: number, size: string) =>
    `/external-service/lexis-nexis/batch/result?resultId=${id}`,
  REQUEST_BATCH_UPLOAD: (
    userId: string,
    companyId: number,
    subCompanyId: number
  ) =>
    `/external-service/lexis-nexis/batch/upload?userId=${userId}&companyId=${companyId}${
      subCompanyId !== 0 ? "&subCompanyId=" + subCompanyId : ""
    }`,

  // Search Client external service

  REQUEST_SEARCH_CLIENT:
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/lexis-nexis/search`,
  REQUEST_SEARCH_CLIENT_BLACKLIST_INTERNALLIST:
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/black-list/search`,
  REQUEST_COUNTRIES: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/country`,

  REQUEST_CONSTITUTION_TIME:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/constitution-time`,

  /** Profile History Risk Matriz*/
  REQUEST_PROFILE_HISTORY: (
    companyId: number,
    subCompanyId: string,
    startDate: string,
    endDate: string,
    page: number,
    size: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/profile/history?company=${companyId}${
      subCompanyId !== "" ? "&subcompany=" + subCompanyId : ""
    }&date-from=${startDate}&date-to=${endDate}&page=${page}&size=${size}`,

  REQUEST_DOWNLOAD_PROFILE_HISTORY: (
    companyId: number,
    subCompanyId: string,
    startDate: string,
    endDate: string
  ) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/profile/history/excel?company=${companyId}${
      subCompanyId !== "" ? "&subcompany=" + subCompanyId : ""
    }&date-from=${startDate}&date-to=${endDate}`,

  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/v1/maintenance/profile/history?company=127&date-from=01%2F01%2F2024&date-to=01%2F01%2F2025&page=0&size=10

  //https://mapprisk-compliance-dispatcher.bitcode-enterprise.dev/v1/maintenance/profile/history/excel?company=157&date-from=14%2F11%2F2024&date-to=14%2F02%2F2025
};
