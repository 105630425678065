import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
  TextField,
  Box,
  FormControl,
  FormHelperText,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import InputLabelToForm from "../components/InputLabelToForm";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { properties } from "../utils/Properties_es";
import { useApp } from "../hooks/useApp";
import DeleteModal from "../components/utils/DeleteModal";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import {
  getRequestProfileHistory,
  getRequestDownloadProfileHistory,
} from "../services/settings/HistoricalService";
import { formatDate, formatDateHyphen } from "../utils/UtilsFunctions";
import { baseResponse } from "../types/baseRequest";
import { historicalResponse } from "../types/History";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface historicalRequestType {
  startDate: Date;
  endDate: Date;
}

const ManagementHistory = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    authInfo,
    companyIndexDB,
    subCompanyData,
    handleLocalCompaniesAndSubcompanies,
  } = useApp();

  const theme = useTheme();
  const [history, setHistory] = useState<historicalResponse | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(0);

  const validation = Yup.object().shape({
    startDate: Yup.date().default(() => new Date()),
    endDate: Yup.date().default(() => new Date()),
  });

  const defaultValues = {
    startDate: new Date(),
    endDate: new Date(),
  };

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm<historicalRequestType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_startDate = watch("startDate");
  const watch_endDate = watch("endDate");

  useEffect(() => {
    if (companyIndexDB === null) {
      handleLocalCompaniesAndSubcompanies &&
        handleLocalCompaniesAndSubcompanies();
    }
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      if (companyIndexDB.company !== null) {
        if (companyIndexDB.company.length !== 0) {
          await handleFetchHistorical(page);
        }
      }
    };
    if (companyIndexDB !== null) {
      dataInit();
    }
  }, [companyIndexDB]);

  const handleFetchHistorical = async (page: number) => {
    try {
      setLoading && setLoading(true);
      if (companyIndexDB.company !== null) {
        if (companyIndexDB.company.length !== 0) {
          const request: historicalResponse | null =
            await getRequestProfileHistory(
              companyIndexDB.company.id,
              companyIndexDB.subCompany !== null &&
                companyIndexDB.subCompany?.length !== 0 &&
                companyIndexDB?.subCompany?.id !== undefined
                ? companyIndexDB?.subCompany?.id
                : "",
              formatDate(new Date(watch_startDate)),
              formatDate(new Date(watch_endDate)),
              page
            );
          setHistory(request);
          setLoading && setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchHistorical(customPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadHistorical = async () => {
    try {
      setLoading && setLoading(true);
      if (companyIndexDB.company !== null) {
        if (companyIndexDB.company.length !== 0) {
          await getRequestDownloadProfileHistory(
            companyIndexDB.company.id,
            companyIndexDB.subCompany !== null &&
              companyIndexDB.subCompany?.length !== 0
              ? companyIndexDB.subCompany.id
              : "",
            watch_startDate,
            watch_endDate
          );

          setLoading && setLoading(false);
        }
      }
    } catch (error) {}
  };

  const onSubmit = async () => {
    switch (modalData?.modalType) {
      default:
        await handleFetchHistorical(page);
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Historico de matrices">
        <Grid
          container
          sx={{ pb: 8 }}
          spacing={8}
          justifyContent="space-between"
        >
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"startDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.startDate && Boolean(errors.startDate)}
                  >
                    Fecha de inicio
                  </InputLabelToForm>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      value={dayjs(field.value)}
                      minDate={dayjs(new Date(2015, 0, 1))}
                      maxDate={dayjs(new Date())}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
              {...(errors.startDate && (
                <FormHelperText>{errors.startDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"endDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.endDate && Boolean(errors.endDate)}
                  >
                    Fecha de fin
                  </InputLabelToForm>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DatePicker
                      value={dayjs(field.value)}
                      maxDate={dayjs(new Date())}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
              {...(errors.endDate && (
                <FormHelperText>{errors.endDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Box
              display="flex"
              gap={4}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Aplicar
              </Button>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            {history !== null && history.showExcelButton === true && (
              <Box
                display="flex"
                gap={4}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadHistorical}
                >
                  Descargar
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Número de Identificación</TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Razón Social </TableCell>
                <TableCell align="center">Nivel de riesgo anterior</TableCell>
                <TableCell align="center">Nivel de riesgo actual</TableCell>
                <TableCell align="center">Pep</TableCell>
                <TableCell align="center">Lista negra</TableCell>
                <TableCell align="center">
                  Fecha anterior última matriz
                </TableCell>
                <TableCell align="center">Fecha última matriz</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history !== null &&
              history?.profileHistoryPage?.content?.length > 0 ? (
                history?.profileHistoryPage?.content.map(
                  (item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{item.idNumber}</TableCell>
                      <TableCell align="center">{item.name ?? "N/A"}</TableCell>
                      <TableCell align="center">
                        {item.socialName ?? "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.prevRiskLevel ?? ""}
                      </TableCell>
                      <TableCell align="center">{item.riskLevel}</TableCell>
                      <TableCell align="center">
                        {item.pep === true ? (
                          <DoneAllIcon color="success" />
                        ) : (
                          <CancelIcon />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.blackListMatch === true ? (
                          <DoneAllIcon color="success" />
                        ) : (
                          <CancelIcon />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.prevLastMatrizDate}
                      </TableCell>
                      <TableCell align="center">
                        {item.lastMatrizDate}
                      </TableCell>
                      <TableCell align="center">
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            /* data-id={row.id}
                            onClick={handleOpenModal} */
                            data-name="botón 2"
                          >
                            <PenIcon />
                          </IconButton>
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            /* data-id={row.id}
                            onClick={handleOpenModal} */
                            data-name="botón 1"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No existen elementos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            history !== null && history?.profileHistoryPage?.content?.length > 0
              ? history?.profileHistoryPage?.content?.length
              : 0
          }
          rowsPerPage={history !== null ? history.size : 0}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={
            history !== null ? history.profileHistoryPage?.totalElements : 0
          }
          totalPages={
            history !== null ? history.profileHistoryPage?.totalPages : 0
          }
          numberOfElements={
            history !== null ? history?.profileHistoryPage?.numberOfElements : 0
          }
        />
      </FancyPaper>
    </>
  );
};

export default ManagementHistory;
