import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import {
  formatDate,
  formatDateHyphen,
  formatDateToNewDate,
} from "../../utils/UtilsFunctions";
import { UrlBase } from "../../url/Urls";
import { historicalResponse } from "../../types/History";

export const getRequestProfileHistory = async (
  companyId: number,
  subCompanyId: string,
  startDate: string,
  endDate: string,
  page: number
) => {
  let responseData: historicalResponse | null = null;

  await privateFetch
    .get(
      URL.REQUEST_PROFILE_HISTORY(
        companyId,
        subCompanyId,
        startDate,
        endDate,
        page,
        UrlBase.registration_x_page
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const getRequestDownloadProfileHistory = async (
  companyId: number,
  subCompanyId: string,
  startDate: Date,
  endDate: Date
): Promise<void> => {
  try {
    const url = `${UrlBase.base_url}${
      UrlBase.api_context
    }${URL.REQUEST_DOWNLOAD_PROFILE_HISTORY(
      companyId,
      subCompanyId,
      formatDate(new Date(startDate)),
      formatDate(new Date(endDate))
    )}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Error al descargar el archivo. Código: ${response.status}`
      );
    }

    // Obtener el Blob de la respuesta
    const blob = await response.blob();

    // Forzar el tipo MIME correcto para Excel
    const excelBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(excelBlob);
    link.download = `historico-matriz-desde_${formatDateHyphen(
      new Date(startDate)
    )}_a_${formatDateHyphen(new Date(endDate))}.xlsx`; // Nombre del archivo con extensión .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error: any) {
    console.error("Error al descargar el archivo:", error.message);
    throw new Error("Error al descargar el archivo:", error.message);
  }
};
