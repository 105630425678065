import React, { useEffect, useState } from "react";
import RiskLevelsContainer from "./RiskLevelsContainer";
import {
  getRiskLevels,
  createRiskLevel,
  updateRiskLevels,
} from "../../services/riskLevels/RiskLevelsService";
import { ApiRiskLevelResponse, RiskLevel } from "./RiskLevelsModel";
import ButtonPrimary from "../../components/ButtonPrimary";
import FancyPaper from "../../components/FancyPaper";
import { useApp } from "../../hooks/useApp";
import {
  Alert,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { properties } from "../../utils/Properties_es";
import ErrorAlert from "../../components/utils/ErrorAlert";

const RiskLevelsPage: React.FC = () => {
  const theme = useTheme();
  const {
    setLoading,
    setErrorMsg,
    authInfo,
    setSuccessMsg,
    subCompanyData,
    handleFetchRiskLevels,
    riskData,
    defaultRiskData,
    setDefaultRiskData,
    companyIndexDB,
    handleLocalCompaniesAndSubcompanies,
  } = useApp();

  const [companyID, setCompanyID] = useState("");
  const [subCompanyID, setSubCompanyID] = useState("");
  const [laftRiskModifiedData, setLaftRiskModifiedData] =
    useState<RiskLevel | null>(null);
  const [inherentRiskModifiedData, setInherentRiskModifiedData] =
    useState<RiskLevel | null>(null);

  useEffect(() => {
    if (companyIndexDB === null) {
      handleLocalCompaniesAndSubcompanies &&
        handleLocalCompaniesAndSubcompanies();
    }
  }, []);

  useEffect(() => {
    if (authInfo.userRole.roleName === "Administrador") {
      if (companyIndexDB !== null || companyIndexDB?.subCompany !== null) {
        setCompanyID(companyIndexDB?.company?.id);
        setSubCompanyID(companyIndexDB?.subCompany?.id);
        handleFetchRiskLevels &&
          handleFetchRiskLevels(
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id
          );
      }
    } else {
      setCompanyID(authInfo.companyInfo[0].id);
      setSubCompanyID(companyIndexDB?.subCompany?.id);
      handleFetchRiskLevels &&
        handleFetchRiskLevels(
          companyIndexDB?.company?.id,
          companyIndexDB?.subCompany?.id
        );
    }
  }, [companyIndexDB]);

  const handleModifiedDataChange = (modifiedData: RiskLevel, type: string) => {
    if (type === "1") {
      setInherentRiskModifiedData(modifiedData);
    } else if (type === "2") {
      setLaftRiskModifiedData(modifiedData);
    }
  };

  const handleSubmmit = async () => {
    console.log(inherentRiskModifiedData);
    console.log(laftRiskModifiedData);

    if (inherentRiskModifiedData && laftRiskModifiedData) {
      setLoading && setLoading(true);
      try {
        if (
          inherentRiskModifiedData.id === 0 &&
          laftRiskModifiedData.id === 0
        ) {
          // Ambos RiskLevels tienen IDs igual a 0, por lo que se deben crear
          await createRiskLevel(
            [inherentRiskModifiedData, laftRiskModifiedData],
            authInfo?.username
          );
          setSuccessMsg &&
            setSuccessMsg(properties.com_mapprisk_label_risk_levels_create);
        } else {
          // Al menos uno de los RiskLevels tiene un ID distinto de 0, por lo que se deben actualizar
          await updateRiskLevels(
            inherentRiskModifiedData.id,
            laftRiskModifiedData.id,
            authInfo?.username,
            [laftRiskModifiedData, inherentRiskModifiedData]
          );
          setSuccessMsg &&
            setSuccessMsg(properties.com_mapprisk_label_risk_levels_update);
        }

        handleFetchRiskLevels && handleFetchRiskLevels(companyID, subCompanyID);

        setLoading && setLoading(false);
      } catch (error: any) {
        setLoading && setLoading(false);
        setErrorMsg && setErrorMsg(error.message);
        //console.log(error);
      }
    } else {
      console.log("Los datos de RiskLevel no son válidos.");
    }
  };

  /* const handleFetchRiskLevels = async (companyId: string, subcompanyId: string) => {
        setLoading && setLoading(true);
        try {
            let data = await getRiskLevels(companyId, subcompanyId);

            // Validar que cada elemento en `details` tenga al menos 3 elementos
            const isValid = data.every((item) => item.details.length >= 3);

            if (data.length && isValid) {

                setSnackbarOpen(false);
                setRiskData(data);
                console.log('data');
            } else {
                setSnackbarOpen(true);
                setRiskData(null);
                console.log('default');
            }

            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
            console.log(error);
        }
    }; */

  const inherentRisk = riskData?.find((item: any) => item.type === "1");
  const laftRisk = riskData?.find((item: any) => item.type === "2");

  return (
    <>
      <FancyPaper pagetitle="Mantenimiento / Niveles de riesgo">
        {companyIndexDB !== null && companyIndexDB?.company?.id /*  ||
        companyIndexDB.subCompany !== null */ ? (
          <>
            {defaultRiskData && (
              <ErrorAlert message="Estos son datos preestablecidos." />
            )}
            <Grid container spacing={6} justifyContent={"space-around"}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    boxShadow:
                      "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
                    background: "#fafafa",
                    overflow: "visible",
                  }}
                >
                  <CardContent sx={{ padding: theme.spacing(8) }}>
                    <RiskLevelsContainer
                      data={inherentRisk}
                      companyId={companyID}
                      subcompanyId={subCompanyID}
                      type="1"
                      onModifiedDataChange={(modifiedData) =>
                        handleModifiedDataChange(modifiedData, "1")
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    boxShadow:
                      "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
                    background: "#fafafa",
                    overflow: "visible",
                  }}
                >
                  <CardContent sx={{ padding: theme.spacing(8) }}>
                    <RiskLevelsContainer
                      data={laftRisk}
                      companyId={companyID}
                      subcompanyId={subCompanyID}
                      type="2"
                      onModifiedDataChange={(modifiedData) =>
                        handleModifiedDataChange(modifiedData, "2")
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonPrimary
                  type="button"
                  data-name="create"
                  onClick={() => handleSubmmit()}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  Aplicar cambios
                </ButtonPrimary>
              </Grid>
            </Grid>
          </>
        ) : (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        )}
      </FancyPaper>
    </>
  );
};

export default RiskLevelsPage;
