import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete,
  Dialog,
  Slider,
  Typography,
  styled,
  Checkbox,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../../components/svgicons/SvgIcons";
import { useApp } from "../../hooks/useApp";
import PieChartDashboard from "../../components/PieChartDashboard";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createUpdFactors } from "../../services/factors/FactorsService";
import { properties } from "../../utils/Properties_es";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  //color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const SettingLevelFactors = () => {
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    errorMsg,
    modalData,
    setModalData,
    companyData,
    handleFetchDataCompany,
    subCompanyData,
    setSubCompanyData,
    handleFetchDataSubCompany,
    companyIndexDB,
    factors,
    handleFetchFactors,
    valueTab,
  } = useApp();

  const [values, setValues] = useState<any>(null);

  useEffect(() => {
    if (valueTab === 0) {
      //setValues(factors?.dataPhysical?.content);
      setValues(factors?.dataPhysicalGlobal?.content);
    } else {
      //setValues(factors?.dataLegal?.content);
      setValues(factors?.dataLegalGlobal?.content);
    }
  }, []);

  /*  const schema_options = {
    name: Yup.string().required("Campo es requerido"),    
  };

  const validation = Yup.object().shape({
    newFactor: Yup.array().of(Yup.object().shape(schema_options)),
  }); */

  const getDataTable = (array: any) => {
    const data = [];
    // Add a header row to the array
    data.push(["Name", "Count"]);
    // Iterate over the physical data array and add each item to the results array
    array?.forEach((item: any) => {
      data.push([item.name, item.percentage]);
    });
    // Calculate the sum of all percentages
    const totalPercentage = data.reduce(
      (total: any, item: any) => typeof item[1] === "number" && total + item[1],
      0
    );
    // If the sum of all percentages is not 100, add a new row to the results array with the difference
    if (Number(totalPercentage) !== 100) {
      data.push(["Sin asignar", 100 - Number(totalPercentage)]);
    }
    // Return the results array
    return data;
  };

  const data = getDataTable(
    valueTab === 0
      ? factors?.dataPhysicalGlobal?.content
      : factors?.dataLegalGlobal?.content
  );

  const defaultValues = {
    riskLevels: factors?.dataPhysicalGlobal?.content
      ? factors.dataPhysicalGlobal.content
      : [
          {
            id: 0,
            name: "",
            company: 0,
            editable: true,
            high_risk: false,
            matrix_group: 0,
            percentage: 0,
            sub_company: 0,
            type: "",
          },
        ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues,
    //resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: riskLevelsFields,
    append: riskLevelsAppend,
    remove: riskLevelsRemove,
  } = useFieldArray({
    control,
    name: "riskLevels",
  });

  /* const handleChange = (
    event: any,
    newValue: number | number[],
    activeThumb: number
  ) => {
    //console.log(newValue, event?.target.name);
    const index = event?.target.name;
    setValues((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[index].percentage = newValue;
      return newValues;
    });
  }; */

  const handleUpdateFactor = async () => {
    setLoading && setLoading(true);
    console.log(values);
    try {
      let response = await createUpdFactors(values);
      if (response) {
        setSuccessMsg &&
          setSuccessMsg(properties.com_mapprisk_label_request_update);
        handleFetchFactors &&
          handleFetchFactors(
            0,
            companyIndexDB?.company?.id,
            companyIndexDB?.subCompany?.id
          );
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    // Obtener el índice del elemento actual
    const index = event?.target.name;
    //console.log("índice del elemento actual: ", index);
    // Obtener el valor actual del percentage
    //const currentValue = values[index].percentage;;:<>
    //console.log("newValue:", newValue);
    // Incrementar o decrementar el valor del percentage
    //const newPercentage = newValue + currentValue;

    // Verificar que la suma de todos los percentages no sea mayor a 100
    const totalPercentage = values.reduce(
      (sum: number, item: any) => sum + item.percentage,
      0
    );
    /* console.log(
      "suma de todos los percentages no sea mayor a 100: ",
      totalPercentage
    ); */
    const preValue = values[index].percentage;
    //@ts-ignore
    const currentValue: any = preValue - newValue;

    //if (totalPercentage + newValue > 100) {
    if (totalPercentage + -currentValue > 100) {
      return;
    }

    // Actualizar el valor del percentage en el elemento actual
    setValues((prevState: any) => {
      const newState = [...prevState];
      newState[index].percentage = newValue;
      return newState;
    });
  };

  const handleChangeChecked = async (event: ChangeEvent<HTMLInputElement>) => {
    const index: any = event.target.getAttribute("id");
    setValues((prevState: any) => {
      const newState = [...prevState];
      newState[index].high_risk = event.target.checked;
      return newState;
    });
  };

  /* const onSubmit = async (data: any) => {
    handleUpdateFactor;
  }; */

  return (
    <FancyPaper
      prevpage="Factores "
      linkprevpage="/dashboard/factorsManagement"
      pagemenutitle="Mantenimiento "
      pagetitle="Ajustes"
    >
      <Grid container spacing={4} sx={{ pb: 8 }}>
        <Grid item xs={12} sm={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Factores</TableCell>
                <TableCell align="right">Alto riesgo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} sx={{ pt: "25px!important" }}>
                  {values !== null &&
                    values?.map((item: any, index: any) => (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={3}
                        sx={{ mb: "30px!important" }}
                        key={index}
                      >
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "25%",
                            },
                          }}
                        >
                          {item.name}
                        </Typography>
                        <IOSSlider
                          aria-label={item.name}
                          defaultValue={item.percentage}
                          value={values[index].percentage}
                          valueLabelDisplay="on"
                          step={2.5}
                          marks
                          min={0}
                          max={50}
                          disabled={!item.editable}
                          onChange={handleChange}
                          name={index}
                          aria-labelledby={index}
                        />

                        <Checkbox
                          id={index}
                          disabled={!item.editable}
                          onChange={handleChangeChecked}
                          checked={item.high_risk}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                      </Stack>
                    ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <Button variant="contained" onClick={handleUpdateFactor}>
                    Guardar
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PieChartDashboard
            title="Distribución de pesos"
            data={values === null ? data : getDataTable(values)}
            titleChart=" "
            sx={{
              boxShadow:
                "0px 1px 1px 1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)",
              background: "#fafafa",
            }}
          />
        </Grid>
      </Grid>
    </FancyPaper>
  );
};

export default SettingLevelFactors;
