import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import { useForm, useFormState, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import { en_es } from "../../components/utils/I18n";
import { useApp } from "../../hooks/useApp";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

const AddOrUpdateRubro = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const validationSchema = (nameInputs: DataType) =>
    Yup.object().shape(
      Object.keys(nameInputs).reduce((schema, key) => {
        return {
          ...schema,
          [key]:
            key === "company" || key === "subcompany"
              ? Yup.object()
                  .shape({
                    id: Yup.string().required("Required"),
                    description: Yup.string().required("Required"),
                  })
                  .typeError("Campo es requerido")
              : Yup.string().required("Campo es requerido"),
        };
      }, {})
    );

  const {
    id,
    tableName,
    type,
    weight,
    updateddAt,
    createdAt,
    updateAt,
    userCreated,
    userModified,
    createdBy,
    createddAt,
    ...nameInputs
  }: any = data;

  const {
    companyData,
    handleFetchDataCompany,
    subCompanyData,
    handleFetchDataSubCompany,
  } = useApp();
  const [watchCompanyCount, setWatchCompanyCount] = useState(0);

  type DataType = typeof nameInputs;

  const validation = validationSchema(nameInputs);

  const defaultValues = Object.keys(data).reduce((values, key) => {
    return {
      ...values,
      [key]: modalType === "create" ? "" : data[key],
      //[key]: data[key] || "",
    };
  }, {});

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
    setValue,
  } = useForm<DataType>({
    defaultValues: defaultValues,
    //defaultValues: modalType === "create" ? {} : defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const watchCompany: any = watch("company");
  const watchSubCompany: any = watch("subcompany");

  useEffect(() => {
    handleFetchDataCompany && handleFetchDataCompany(0, "");
  }, []);

  useEffect(() => {
    if (watchCompany !== undefined || data?.company?.id) {
      handleFetchDataSubCompany(0, watchCompany?.id);
    }
  }, [watch, watchCompany]);

  useEffect(() => {
    setWatchCompanyCount(watchCompanyCount + 1);
    if (watchCompanyCount > 1) {
      resetField("subcompany", { defaultValue: [] });
    }
  }, [watchCompany]);

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar ítem" : "Actualizar ítem"}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {Object.keys(nameInputs).map((key, index) =>
              key === "company" ? (
                <Grid item xs={12} sm={6} key={index}>
                  <Controller
                    name={key}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        fullWidth
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={companyData && companyData}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={en_es[key]}
                            variant="standard"
                            error={errors[key] && Boolean(errors[key])}
                            //@ts-ignore
                            helperText={errors[key] && errors[key].message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : key === "subcompany" ? (
                <Grid item xs={12} sm={6} key={index}>
                  <Controller
                    name={key}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        fullWidth
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={subCompanyData ? subCompanyData : []}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        /* inputValue={value?.description}
                        onInputChange={(event, newInputValue) => {
                          onChange(newInputValue);
                        }}
                        data-algo={value?.id} */
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={en_es[key]}
                            variant="standard"
                            placeholder=""
                            error={errors[key] && Boolean(errors[key])}
                            //@ts-ignore
                            helperText={errors[key] && errors[key].message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} key={index}>
                  <Controller
                    name={key}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={en_es[key]}
                        type="text"
                        name={key}
                        variant="standard"
                        value={value}
                        sx={{ "& input": { pl: "0!important" } }}
                        onChange={(e) => onChange(e.target.value)}
                        error={errors[key] && Boolean(errors[key])}
                        // @ts-ignore
                        helperText={errors[key] && errors[key].message}
                      />
                    )}
                  />
                </Grid>
              )
            )}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={() => {
            cancelModal();
            setWatchCompanyCount(0);
          }}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRubro;
