import { useState, useEffect } from "react";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";

const DocumentationManagement = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    authInfo,
    companyIndexDB,
    subCompanyData,
    handleLocalCompaniesAndSubcompanies,
  } = useApp();

  useEffect(() => {
    if (companyIndexDB === null) {
      handleLocalCompaniesAndSubcompanies &&
        handleLocalCompaniesAndSubcompanies();
    }
  }, []);

  return (
    <>
      <FancyPaper pagetitle="Documentación">Documentación</FancyPaper>
    </>
  );
};

export default DocumentationManagement;
