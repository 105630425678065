import { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import Step1Agrupacion from "./Step1Agrupacion";
import { getRequestUserMatriz } from "../../services/users/UserMatrizService";
import {
  getConstitutionTime,
  getRequestAgrupacion,
} from "../../services/agrupaciones/AgrupacionService";
import {
  createProfileMatriz,
  getProfileMatriz,
} from "../../services/profile/ProfileService";
import Step2ValidateClient from "./Step2ValidateClient";
import Step3Result from "./Step3Result";
import { documentNameType } from "../../types/ValidateClient";
import { getInternalListDocument } from "../../services/externalapi/ExternalApi";
import { getIndexData } from "../../utils/LocalStorageManager";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RiskMatrixTabs = () => {
  const {
    setLoading,
    setErrorMsg,
    riskData,
    setSuccessMsg,
    companyIndexDB,
    subCompanyData,
    handleFetchFactors,
    factors,
    handleLocalCompaniesAndSubcompanies,
    handleFetchRiskLevels,
    defaultRiskData,
  } = useApp();
  const [valueStep, setValueStep] = useState<number>(0);
  const [dataConstitutionTime, setDataConstitutionTime] = useState<any>(null);
  const [showInitModal, setShowInitModal] = useState<boolean>(false);
  const [dataUserSearched, setDataUserSearched] = useState<any>(null);
  const [dataUserSearchedShow, setDataUserSearchedShow] = useState<any>(null);
  const [optionsTipoPersona, setOptionsTipoPersona] = useState<any>([]);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expendedTab1, setExpendedTab1] = useState<boolean>(true);
  const [expendedTab2, setExpendedTab2] = useState<boolean>(false);
  const [factorsAndOptions, setFactorsAndOptions] = useState<any>(null);
  const [listShow, setListShow] = useState<{ label: string }[]>([]);
  const [matrixGroups, setMatrixGroups] = useState<any>(null);
  const [branchData, setBranchData] = useState<any>(null);
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [optionsEjecutivo, setOptionsEjecutivo] = useState<any>(null);
  const [isPep, setIsPep] = useState<any>([]);
  const [comments, setComments] = useState<string>("");
  const [commentsFactor, setCommentsFactor] = useState<string>("");

  const [documentName, setDocumentName] = useState<documentNameType | null>(
    null
  );

  {
    /**Estados de ValidateClient */
  }
  const [listSearch, setListSearch] = useState<any>(null);
  const [listSearchBlackList, setListSearchBlackList] = useState<any>(null);
  const [listSearchCRCResult, setListSearchCRCResult] = useState<any>(null);
  const [listSearchJCEResult, setListSearchJCEResult] = useState<any>(null);

  const [runUploadDataInForm, setRunUploadDataInForm] =
    useState<boolean>(false);

  const [localCompanyAdminData, setLocalCompanyAdminData] = useState<any>(null);
  const [localSubCompanyAdminData, setLocalSubCompanyAdminData] =
    useState<any>(null);
  const [cleanData, setCleanData] = useState(false);
  const localCompanyAdminDataRef = useRef(localCompanyAdminData);
  const localSubCompanyAdminDataRef = useRef(localSubCompanyAdminData);

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueStep(newValue);
  };

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!companyIndexDB?.company?.id) return;

      if (
        !defaultRiskData &&
        (subCompanyData === null ||
          companyIndexDB?.subCompany?.id === undefined)
      ) {
        handleFetchFactors &&
          handleFetchFactors(0, companyIndexDB.company.id, "", 100);
      } else {
        if (!defaultRiskData && companyIndexDB?.subCompany?.id !== undefined) {
          handleFetchFactors &&
            handleFetchFactors(
              0,
              companyIndexDB.company.id,
              companyIndexDB?.subCompany?.id,
              100
            );
        }
      }

      const getLocalCompanyDataAdmin: any = await getIndexData(
        "setLocalCompany"
      );
      const getLocalSubCompanyDataAdmin: any = await getIndexData(
        "setLocalSubCompany"
      );

      setLocalCompanyAdminData(getLocalCompanyDataAdmin);
      if (getLocalSubCompanyDataAdmin?.id) {
        setLocalSubCompanyAdminData(getLocalSubCompanyDataAdmin);
      }
      if (
        companyIndexDB !== null &&
        (riskData === null || riskData?.length < 1)
      ) {
        handleFetchRiskLevels &&
          handleFetchRiskLevels(
            companyIndexDB?.company.id,
            companyIndexDB?.subCompany?.id
          );
      }
    };

    fetchData();
  }, [companyIndexDB, subCompanyData]);

  let count: number = 0;
  const handleInternalListDocument = async (
    company: string,
    subCompany?: string
  ) => {
    count++;
    try {
      //setLoading && setLoading(true);
      let data: any = await getInternalListDocument(company, subCompany);
      if (data) {
        setDocumentName(data);
        setLoading && setLoading(false);
      }
      //setLoading && setLoading(false);
    } catch (error: any) {
      // setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (
      localCompanyAdminData !== null &&
      localCompanyAdminData?.screeningCompanyId !== null
    ) {
      handleInternalListDocument(localCompanyAdminData?.screeningCompanyId);
    }
    if (
      localSubCompanyAdminData !== null &&
      localSubCompanyAdminData.screeningCompanyId !== null
    ) {
      handleInternalListDocument(
        localSubCompanyAdminData?.screeningCompanyId,
        localSubCompanyAdminData?.screeningSubCompanyId
      );
    }
  }, [localCompanyAdminDataRef, localSubCompanyAdminDataRef]);

  /*  <ErrorAlert
          message={
            companyIndexDB === null ||
                companyIndexDB?.company === null ||
                companyIndexDB.company.length === 0
              ? "Debe seleccionar primero compañía y subcompañía"
              : subCompanyData?.length > 0 &&
                      companyIndexDB?.subCompany === null && "La compañía tiene subcompañías asociadas, debe seleccionar una de ellas"
          }
        />  */

  return (
    <FancyPaper pagetitle="Mantenimiento / Matriz de riesgo">
      <>
        {companyIndexDB === null ||
        companyIndexDB?.company === null ||
        companyIndexDB.company.length === 0 ? (
          <ErrorAlert message="Debe seleccionar primero compañía y subcompañía" />
        ) : (
          subCompanyData?.length > 0 &&
          companyIndexDB?.subCompany === null && (
            <ErrorAlert message="La compañía tiene subcompañías asociadas, debe seleccionar una de ellas" />
          )
        )}
        {defaultRiskData && (
          <ErrorAlert
            message="Debe establecer primero los valores de nivel de riesgo, haz click en el siguiente enlace"
            handleClick="/dashboard/riskLevels"
            textLink="Niveles de Riesgo"
          />
        )}
      </>
      {!defaultRiskData && (factors !== null || factors.length !== 0) && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={valueStep}
              onChange={handleChange}
              aria-label="tabs risk matrix"
            >
              <Tab label="Matriz de riesgo" {...a11yProps(0)} />
              <Tab
                label="Lexis Nexis"
                {...a11yProps(1)}
                disabled={dataUserSearchedShow === null}
              />
              <Tab
                label="Resultados"
                {...a11yProps(2)}
                disabled={dataUserSearchedShow === null}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueStep} index={0}>
            <Step1Agrupacion
              showInitModal={showInitModal}
              setShowInitModal={setShowInitModal}
              dataUserSearched={dataUserSearched}
              setDataUserSearched={setDataUserSearched}
              dataUserSearchedShow={dataUserSearchedShow}
              setDataUserSearchedShow={setDataUserSearchedShow}
              expanded={expanded}
              setExpanded={setExpanded}
              expendedTab1={expendedTab1}
              setExpendedTab1={setExpendedTab1}
              expendedTab2={expendedTab2}
              setExpendedTab2={setExpendedTab2}
              factorsAndOptions={factorsAndOptions}
              setFactorsAndOptions={setFactorsAndOptions}
              listShow={listShow}
              setListShow={setListShow}
              matrixGroups={matrixGroups}
              setMatrixGroups={setMatrixGroups}
              branchData={branchData}
              setBranchData={setBranchData}
              optionsSelected={optionsSelected}
              setOptionsSelected={setOptionsSelected}
              optionsTipoPersona={optionsTipoPersona}
              setOptionsTipoPersona={setOptionsTipoPersona}
              optionsEjecutivo={optionsEjecutivo}
              setOptionsEjecutivo={setOptionsEjecutivo}
              dataConstitutionTime={dataConstitutionTime}
              setDataConstitutionTime={setDataConstitutionTime}
              setValueStep={setValueStep}
              isPep={isPep}
              setIsPep={setIsPep}
              listSearch={listSearch}
              comments={comments}
              setComments={setComments}
              commentsFactor={commentsFactor}
              setCommentsFactor={setCommentsFactor}
              setListSearchJCEResult={setListSearchJCEResult}
              localCompanyAdminDataRef={localCompanyAdminDataRef}
              localSubCompanyAdminDataRef={localSubCompanyAdminDataRef}
              cleanData={cleanData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={1}>
            <Step2ValidateClient
              dataClient={dataUserSearchedShow}
              setDataUserSearchedShow={setDataUserSearchedShow}
              factorsAndOptions={factorsAndOptions}
              listSearch={listSearch}
              setListSearch={setListSearch}
              listSearchBlackList={listSearchBlackList}
              setListSearchBlackList={setListSearchBlackList}
              listSearchCRCResult={listSearchCRCResult}
              setListSearchCRCResult={setListSearchCRCResult}
              listSearchJCEResult={listSearchJCEResult}
              setListSearchJCEResult={setListSearchJCEResult}
              documentName={documentName}
              setDocumentName={setDocumentName}
              setValueStep={setValueStep}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={2}>
            <Step3Result
              dataClient={dataUserSearchedShow}
              optionsSelected={optionsSelected}
              isPep={isPep}
            />
          </CustomTabPanel>
        </Box>
      )}
    </FancyPaper>
  );
};

export default RiskMatrixTabs;
