import {
  useState,
  createContext,
  useMemo,
  useEffect,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { requestTypes, companiesTypes, options } from "../types/Context";
import {
  getItem,
  setAuthData,
  setItem,
  setItemCompany,
} from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { UrlBase } from "../url/Urls";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { getRiskLevels } from "../services/riskLevels/RiskLevelsService";
import { ApiRiskLevelResponse } from "../pages/maintenance/RiskLevelsModel";
import { getRequestCompanyDropDown } from "../services/company/CompanyService";
import { getRequestSubcompany } from "../services/subCompany/SubCompanyServices";
import { getFactors } from "../services/factors/FactorsService";

const initialState = {
  isLoading: false,
  setLoading: () => {},
  errorMsg: "",
  setErrorMsg: () => {},
  successMsg: "",
  setSuccessMsg: () => {},
  //viene de data context
  page: 0,
  companyData: [],
  loading: false,
  error: "",
  factors: [],
  valueTab: 0,
  hasSubCompany: false,
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(authData != null);
  const [defaultRiskData, setDefaultRiskData] = useState<boolean>(true);
  const [riskData, setRiskData] = useState<ApiRiskLevelResponse | null>(null);
  const navigate = useNavigate();

  /*Viene de Data Context */
  const [companyData, setCompanyData] = useState<options[]>(
    initialState.companyData
  );
  const [subCompanyData, setSubCompanyData] = useState<any>([]);
  const [page, setPage] = useState(initialState.page);
  const [error, setError] = useState(initialState.error);
  const [companyIndexDB, setCompanyIndexDB] = useState<any>(null);
  const [hasSubCompany, setHasSubCompany] = useState<boolean>(false);
  const [factors, setFactors] = useState<any>(initialState.factors);
  const [valueTab, setValueTab] = useState(0);
  /*Fin de Data Context */

  const loginProvider = async (loginData: any) => {
    //console.log("loginData: ", loginData);
    try {
      const sessionCode = loginData.authCode;
      const email = loginData.username;
      const companyInfo = loginData.companyInfo;
      const subcompanyInfo = loginData.subcompanyInfo;
      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode
        );
        let formatData = {
          ...loginDataValidate,
          companyInfo: companyInfo,
          subcompanyInfo: subcompanyInfo,
        };

        //s
        //save login data in storage
        const authData = await setAuthData(
          "authMappRiskCompliance",
          formatData
        );
        const validateAuthData = await getItem("authMappRiskCompliance");
        if (validateAuthData) {
          let getCompany: any = await getRequestCompanyDropDown(0, "");
          // console.log("getCompany: ", getCompany);
          if (getCompany) {
            let clearData = getCompany.map(
              ({ createdAt, createdBy, updatedAt, updateBy, ...rest }: any) =>
                rest
            );
            let findCompany: any = null;
            findCompany = await clearData?.find(
              (item: any) => item.id === companyInfo[0]?.id
            );
            //  console.log("findCompany: ", findCompany);
            if (findCompany !== null) {
              //console.log("Ingresa en save findCompany AppContext");
              await setItemCompany(
                "setLocalCompany",
                Object.keys(findCompany).length > 0 ? findCompany : undefined,
                "Usado en el appContext line 100"
              );
            }

            /* await setItem(
              "setLocalCompany",
              Object.keys(findCompany).length > 0 ? findCompany : undefined
            ); */
          }

          if (subcompanyInfo) {
            const getSubCompanies: any = await getRequestSubcompany(
              0,
              companyInfo[0].id
            );
            if (getSubCompanies.content.length > 0 && subcompanyInfo) {
              const findSubCompany = getSubCompanies.content.find(
                (item: any) => item.id === subcompanyInfo[0].id
              );
              // console.log("findSubCompany: ", findSubCompany);
              if (findSubCompany) {
                await setItem("setLocalSubCompany", findSubCompany);
              } else {
                await setItem("setLocalSubCompany", undefined);
              }
            }
          }
        }

        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
        const expiresAt = decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        //let valid = true;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);
          navigate("/dashboard");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAuthenticate(false);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e: any) => {
    //e.preventDefault();

    await setAuthData("authMappRiskCompliance", null);
    await setItemCompany("setLocalCompany", null, "logoutProvider en null");
    await setItem("setLocalSubCompany", null);
    setCompanyData && setCompanyData([]);
    setSubCompanyData && setSubCompanyData([]);
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const handleFetchRiskLevels = async (
    companyId: string,
    subcompanyId: string
  ) => {
    //setLoading && setLoading(true);
    try {
      if (!companyId) {
        setLoading && setLoading(false);
        return;
      }

      let data = await getRiskLevels(companyId, subcompanyId ?? 0);

      // Validar que cada elemento en `details` tenga al menos 3 elementos
      const isValid = data.every((item) => item.details.length >= 3);

      if (data.length && isValid) {
        setDefaultRiskData(false);
        setRiskData(data);
      } else {
        setDefaultRiskData(true);
        setRiskData(null);
      }

      // setLoading && setLoading(false);
    } catch (error: any) {
      //setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      //console.log(error);
    }
  };

  const handleFetchRiskLevelsClient = async (
    companyId: string,
    subcompanyId: string
  ) => {
    try {
      if (!companyId) {
        return;
      }

      let data = await getRiskLevels(companyId, subcompanyId ?? 0);

      // Validar que cada elemento en `details` tenga al menos 3 elementos
      const isValid = data.every((item) => item.details.length >= 3);

      if (data.length && isValid) {
        setDefaultRiskData(false);
        setRiskData(data);
      } else {
        setDefaultRiskData(true);
        setRiskData(null);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      //console.log(error);
    }
  };

  const handleFetchDataCompany = async (
    currentPage: number,
    filter?: string
  ) => {
    setLoading && setLoading(true);
    try {
      //setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequestCompanyDropDown(currentPage, filter);
      if (data) {
        let clearData = data.map(
          ({ createdAt, createdBy, updatedAt, updateBy, ...rest }: any) => rest
        );
        setCompanyData(clearData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading && setLoading(false);
      setError && setError(error.message);
    }
  };

  const handleFetchDataSubCompany = async (
    currentPage: number,
    filter?: string
  ) => {
    let hasCompany = false;
    try {
      setPage(currentPage);
      let data: any = await getRequestSubcompany(currentPage, filter);
      if (data && data.content.length > 0) {
        hasCompany = true;
        let clearData = data.content.map(({ companyId, ...rest }: any) => rest);
        setSubCompanyData(clearData);
      } else {
        hasCompany = false;
        setSubCompanyData(null);
      }
    } catch (error: any) {
      console.log(error);
    }
    return hasCompany;
  };

  const handleLocalCompaniesAndSubcompanies = async () => {
    let localCompany = await getItem("setLocalCompany");
    /* console.log(
      "handleLocalCompaniesAndSubcompanies localCompany:",
      localCompany
    ); */
    let localSubCompany: any = await getItem("setLocalSubCompany");
    /* console.log(
      "handleLocalCompaniesAndSubcompanies localSubCompany:",
      localSubCompany
    ); */

    setCompanyIndexDB({
      company: localCompany,
      subCompany: localSubCompany,
      //subCompany: localSubCompany?.id ? localSubCompany : null,
    });
  };

  const handleFetchFactors = async (
    page: number,
    companyID: number,
    subCompanyID?: string,
    size?: number
  ) => {
    let dataPhysical: any = null;
    let dataLegal: any = null;
    let dataPhysicalGlobal: any = null;
    let dataLegalGlobal: any = null;
    setLoading && setLoading(true);
    try {
      dataPhysical = await getFactors(page, "F", companyID, subCompanyID, size);
      dataLegal = await getFactors(page, "J", companyID, subCompanyID, size);
      dataPhysicalGlobal = await getFactors(
        page,
        "F",
        companyID,
        subCompanyID,
        100
      );
      dataLegalGlobal = await getFactors(
        page,
        "J",
        companyID,
        subCompanyID,
        100
      );
      if (dataPhysical && dataLegal) {
        setFactors({
          ...factors,
          dataPhysical: dataPhysical,
          dataLegal: dataLegal,
          dataPhysicalGlobal: dataPhysicalGlobal,
          dataLegalGlobal: dataLegalGlobal,
        });
      } else {
        setFactors(null);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setFactors(null);
      setError(error.message);
      setLoading && setLoading(false);
    }
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      defaultRiskData,
      riskData,
      setDefaultRiskData,
      handleFetchRiskLevels,
      handleFetchRiskLevelsClient,
      //Viene de dataContext
      page,
      //preFilter,
      companyData,
      setCompanyData,
      handleFetchDataCompany,
      subCompanyData,
      setSubCompanyData,
      handleFetchDataSubCompany,
      error,
      companyIndexDB,
      handleLocalCompaniesAndSubcompanies,
      factors,
      setFactors,
      handleFetchFactors,
      valueTab,
      setValueTab,
      hasSubCompany,
      setHasSubCompany,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      defaultRiskData,
      riskData,
      companyData,
      subCompanyData,
      companyIndexDB,
      factors,
      valueTab,
    ]
  );

  // Cerrar sesión

  useEffect(() => {
    let time = 600000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []);

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
