import { useApp } from "../../../hooks/useApp";

const FindDescription = ({ riskValue }: any) => {
  const { riskData } = useApp();
  let captionRiskLegend: any = "N/D";
  const inherentRisk =
    riskData !== null && riskData?.find((item: any) => item.type === "1");
  if (inherentRisk.details?.length !== 0) {
    const matches = inherentRisk?.details?.filter((element: any) => {
      return element.from <= riskValue && riskValue <= element.to;
    });
    //console.log("matches: ", matches);
    /* const findCaption = inherentRisk.details.filter(
      (item: any) => item.id === idRiskLegend
    );

    console.log("findCaption=", findCaption); */
    if (matches?.length > 0) {
      captionRiskLegend = `${matches[0].caption}`;
    }
  }

  return <>{captionRiskLegend}</>;
};

export default FindDescription;
